import { followingSidebar } from '../utils/sidebar.utils.js';

if (document.querySelector('.jobs-search')) {
  const sidebar = document.querySelector('#sidebar');
  const contentSticky = document.querySelector('#content-sticky');
  const filtersBtn = document.querySelector('.filters-btn');
  const btnClose = sidebar.querySelector('.jobs-search__sidebar .btn-close');
  const contentResult = document.querySelector('.jobs-search__result-bg');
  const sidebarItemsArr = document.querySelectorAll(
    '.jobs-search__sidebar-item.with-accordion'
  );
  console.log(contentResult);
  console.log(sidebarItemsArr);
  console.log(btnClose);

  sidebarItemsArr.forEach((item, i) => {
    item.addEventListener('click', () => {
      console.log('click');
      item.classList.toggle('active');
    });
  });

  if (sidebar && window.innerWidth >= 640) {
    followingSidebar({ sidebar, contentSticky });
  }

  if (filtersBtn) {
    filtersBtn.addEventListener('click', () => {
      filtersBtn.classList.toggle('active');
      contentSticky.classList.toggle('active');
      contentResult.classList.toggle('active');
    });

    contentResult?.addEventListener('click', () => {
      if (contentResult.classList.contains('active')) {
        filtersBtn.classList.remove('active');
        contentSticky.classList.remove('active');
        contentResult.classList.remove('active');
      }
    });

    btnClose.addEventListener('click', (e) => {
      if (contentResult.classList.contains('active')) {
        e.preventDefault();
        filtersBtn.classList.remove('active');
        contentSticky.classList.remove('active');
        contentResult.classList.remove('active');
      }
    });
  }
}

const btnShowHideArr = document.querySelectorAll('.btn-show-hide');
const showHideContentArr = document.querySelectorAll('.show-hide-content');

function itemCalcMaxHeight(item, content) {
  const children = Array.from(content.children);
  const totalHeight = children.reduce((acc, child) => {
    const childHeight = child.offsetHeight;
    return acc + childHeight;
  }, 0);
  return totalHeight + 100;
}

showHideContentArr.forEach((content, i) => {
  const items = content.querySelectorAll('.meta-checkbox');
  console.log(items);

  const maxVisibleItems = content.dataset.visible
    ? parseInt(content.dataset.visible)
    : 6;

  console.log(maxVisibleItems);

  items.forEach((item, index) => {
    if (index > maxVisibleItems - 1) {
      item.classList.add('is-hidden');
    }
  });

  if (items.length > maxVisibleItems) {
    const btnShowHide = btnShowHideArr[i];
    btnShowHide.classList.add('is-visible');

    btnShowHide.addEventListener('click', () => {
      btnShowHide.classList.toggle('active');
      items.forEach((item, index) => {
        if (index > maxVisibleItems - 1) {
          item.classList.toggle('is-hidden');
        }
      });
      content.parentElement.style.maxHeight = `${itemCalcMaxHeight(
        content.parentElement.parentElement,
        content.parentElement
      )}px`;
    });
  }
});

if (document.getElementsByClassName('jobs-search__sidebar-accordion')[0]) {
  const jobsAccordionArr = document.querySelectorAll(
    '.jobs-search__sidebar-accordion'
  );

  jobsAccordionArr.forEach((jobsAccordion) => {
    const jobsItemsArr = jobsAccordion.querySelectorAll(
      '.jobs-search__sidebar-accordion-item'
    );

    jobsItemsArr.forEach((item, i) => {
      const btn = item.querySelector(
        '.jobs-search__sidebar-accordion-item-top'
      );
      const content = item.querySelector(
        '.jobs-search__sidebar-accordion-item-content'
      );

      /*item.addEventListener('click', (event) => {
        if (event.target !== btn) {
          openAccordion(item);
        } else if (event.target === btn && item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });*/

      btn.addEventListener('click', () => {
        console.log('click');

        if (item.classList.contains('active')) {
          item.classList.remove('active');
        } else {
          openAccordion(item);
        }
      });

      function openAccordion(item) {
        // for (let j = 0; j < jobsItemsArr.length; j++) {
        //   jobsItemsArr[j].classList.remove('active');
        // }

        item.classList.add('active');
        content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
      }
    });
  });
}

const alertSection = document.querySelector('.alert-section');
if (alertSection) {
  document.querySelector('.footer__wrap').classList.add('not-line');
}
