if (document.querySelector('.hiring-section')) {
  const hiringSection = document.querySelector('.hiring-section');
  const hiringRadiosArr = hiringSection.querySelectorAll(
    '.filters-slider__item input'
  );
  const hiringTabsArr = hiringSection.querySelectorAll('.hiring-section__item');

  function switchTab(index) {
    hiringTabsArr.forEach((tab, i) => {
      tab.classList.remove('active');
    });

    if (hiringTabsArr[index]) {
      hiringTabsArr[index].classList.add('active');
    }
  }

  hiringRadiosArr.forEach((radio, index) => {
    radio.addEventListener('change', () => {
      if (radio.checked) {
        switchTab(index);
      }
    });
  });

  switchTab(0);
}
