import marquee from 'vanilla-marquee/dist/vanilla-marquee.js';

if (document.querySelector('.testimonials-section__marquee')) {
  const marqueeArray = [];

  const marqueeArr = document.querySelectorAll(
    '.testimonials-section__marquee'
  );
  marqueeArr.forEach((marqueeEl, i) => {
    /* const itemsArr = marqueeEl.querySelectorAll('.testimonial-card');
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });*/

    const container = 1440;

    const finalItemsArr = marqueeEl.querySelectorAll('.testimonial-card');

    let duration = 20000;

    const totalWidth =
      finalItemsArr.length * (finalItemsArr[0].clientWidth + 12);
    let baseSpeed = 4;

    const scalingFactor = window.innerWidth < 768 ? 1 : 2;
    baseSpeed *= scalingFactor;

    duration = (totalWidth / window.innerWidth) * baseSpeed;

    let direction = 'up';
    let pauseOnHover = true;

    if (window.innerWidth <= 1024) {
      pauseOnHover = false;
    }

    direction = 'left';
    if (i % 2) {
      direction = 'right';
    }

    const mq = new marquee(marqueeEl, {
      delayBeforeStart: 0,
      direction: direction,
      pauseOnHover: pauseOnHover,
      duplicated: true,
      recalcResize: true,
      startVisible: true,
      gap: window.innerWidth >= 768 ? 20 : 16,
      duration: duration * 1000,
    });

    marqueeArray.push(mq);

    if (window.innerWidth <= 1024) {
      setTimeout(function () {
        const itemsArr = marqueeEl.querySelectorAll('.testimonial-card');
        itemsArr.forEach((item) => {
          item.addEventListener('click', (e) => {
            if (item.classList.contains('is-hover')) {
              item.classList.remove('is-hover');
              mq.resume();
            } else {
              const newArr = document.querySelectorAll('.testimonial-card');
              for (let j = 0; j < newArr.length; j++) {
                newArr[j].classList.remove('is-hover');
              }
              item.classList.add('is-hover');
              for (let j = 0; j < marqueeArray.length; j++) {
                marqueeArray[j].resume();
              }
              mq.pause();
            }
          });
        });
      }, 100);
    }
  });

  document.addEventListener('click', function (event) {
    if (!marqueeArr[0].parentElement.contains(event.target)) {
      const newArr = document.querySelectorAll('.testimonial-card');
      for (let j = 0; j < newArr.length; j++) {
        newArr[j].classList.remove('is-hover');
      }
      for (let j = 0; j < marqueeArray.length; j++) {
        marqueeArray[j].resume();
      }
    }
  });
}
