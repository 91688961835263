const processContainer = document.querySelector('.process-section__container');

if (processContainer) {
  const processTabs = processContainer.querySelectorAll(
    '.process-section__tab'
  );
  const processMobTabs = processContainer.querySelectorAll(
    '.process-section__mob-tab'
  );
  const processItems = processContainer.querySelectorAll(
    '.process-section__item'
  );

  processTabs.forEach((tab, i) => {
    tab.addEventListener('click', () => {
      for (let j = 0; j < processTabs.length; j++) {
        processTabs[j].classList.remove('active');
        processMobTabs[j].classList.remove('active');
        processItems[j].classList.remove('active');
      }

      tab.classList.add('active');
      processItems[i].classList.add('active');
      processMobTabs[i].classList.add('active');
    });
  });

  processMobTabs.forEach((mobTab, i) => {
    mobTab.addEventListener('click', () => {
      for (let j = 0; j < processTabs.length; j++) {
        processTabs[j].classList.remove('active');
        processMobTabs[j].classList.remove('active');
        processItems[j].classList.remove('active');
      }

      mobTab.classList.add('active');
      processItems[i].classList.add('active');
      processTabs[i].classList.add('active');
    });
  });
}
