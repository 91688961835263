import Swiper, { Autoplay, EffectCreative, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let processSwiperWr = document.querySelectorAll('.process-swiper-wr');
processSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slides = el.querySelectorAll('.swiper-slide');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    function updateSliderContainerHeight(swiper) {
      const activeSlide = slides[swiper.realIndex];
      console.log(activeSlide);

      requestAnimationFrame(() => {
        if (activeSlide) {
          const slideHeight = activeSlide.querySelector(
            '.process-section__item-slide-container'
          ).offsetHeight;
          const style = getComputedStyle(el);
          const paddingTop = parseFloat(style.paddingTop);
          const paddingBottom = parseFloat(style.paddingBottom);
          /*console.log('Высота слайда:', slideHeight);*/
          el.style.height = `${slideHeight + paddingTop + paddingBottom}px`;
          activeSlide.style.height = `${slideHeight}px`;
        }
      });
    }

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      /*spaceBetween: 20,*/
      direction: 'horizontal',
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      grabCursor: true,

      breakpoints: {
        768: {
          spaceBetween: 0,
          direction: 'vertical',
          /*mousewheel: {
            invert: false,
            releaseOnEdges: true,
          },*/
        },
      },
      /*autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },*/
      on: {
        init: function (swiper) {
          updateSliderContainerHeight(swiper);
        },
        slideChange: function (swiper) {
          updateSliderContainerHeight(swiper);
        },
      },
    });
    /*swiperObserver(swiper);*/

    setTimeout(function () {
      updateSliderContainerHeight(swiper);
    }, 1000);

    swiper.on('resize', function () {
      updateSliderContainerHeight(swiper);
    });
  }
});
