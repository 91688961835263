import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/animations.component.js';
import './components/accordion.component.js';
import './components/date.component.js';
import './components/jobs-search.component.js';
import './components/player.component.js';
import './components/faq.component.js';
import './components/hiring.component.js';
import './components/process.component.js';

import './components/marquee.component.js';

/*Sliders*/
import './components/sw-career.component.js';
import './components/sw-jobs.component.js';
import './components/sw-testimonials.component.js';
import './components/sw-blogs.component.js';
import './components/sw-purpose.component.js';
import './components/sw-teams.component.js';
import './components/sw-values.component.js';
import './components/sw-recruitment.component.js';
import './components/sw-our-services.component.js';
import './components/sw-filters.component.js';
import './components/sw-case-studies.component.js';
import './components/sw-sectors.component.js';
import './components/sw-process.component.js';
/*Sliders End*/

import './components/overlayScroll.component.js';

import './libs/countUp.lib.js';

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let duration = +config[1] && +config[1] !== '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] !== '' ? +config[2] : 0;
  let target = config[0];
  let targetElement = document.querySelector(target);

  if (targetElement) {
    let targetOffsetTop = targetElement.offsetTop - paddingTop;
    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth',
    });
    return false;
  }
};

const footer = document.querySelector('.footer');
if (footer) {
  const auraFooterArr = footer.querySelectorAll('.aura-container');
  for (let i = 0; i < auraFooterArr.length; i++) {
    setTimeout(function () {
      auraFooterArr[i].style.display = 'flex';
    }, 1000);
  }
}
